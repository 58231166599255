// Generated by Framer (f043daf)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Powf_0Ebq"];

const variantClassNames = {Powf_0Ebq: "framer-v-ynh2zh"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; background?: string; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "Powf_0Ebq", background: toaKH4Vnc = "rgb(19, 88, 73)", image: LoGuNMAV5, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Powf_0Ebq", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-sEk2l", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-ynh2zh", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Powf_0Ebq"} ref={ref} style={{backgroundColor: toaKH4Vnc, borderBottomLeftRadius: 40, borderBottomRightRadius: 40, borderTopLeftRadius: 40, borderTopRightRadius: 40, boxShadow: "0px 0.6021873017743928px 0.6021873017743928px -0.9166666666666666px rgba(0, 0, 0, 0.09792), 0px 2.288533303243457px 2.288533303243457px -1.8333333333333333px rgba(0, 0, 0, 0.09488), 0px 10px 10px -2.75px rgba(0, 0, 0, 0.081)", ...style}}><motion.div className={"framer-1yxpr6w"} layoutDependency={layoutDependency} layoutId={"GYD0w5F5f"}><Image background={{alt: "", fit: "fill", sizes: "calc(min(405px, 100vw) - 48px)", ...toResponsiveImage(LoGuNMAV5)}} className={"framer-mn5df7"} layoutDependency={layoutDependency} layoutId={"Kjn4e5QMs"} style={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderTopLeftRadius: 20, borderTopRightRadius: 20}}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-sEk2l [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-sEk2l .framer-139los7 { display: block; }", ".framer-sEk2l .framer-ynh2zh { height: 507px; overflow: hidden; position: relative; width: 405px; will-change: transform; }", ".framer-sEk2l .framer-1yxpr6w { align-content: center; align-items: center; bottom: 24px; display: flex; flex: none; flex-direction: column; flex-wrap: nowrap; gap: 24px; justify-content: flex-start; left: 24px; overflow: visible; padding: 0px 0px 0px 0px; position: absolute; right: 24px; top: 24px; }", ".framer-sEk2l .framer-mn5df7 { flex: 1 0 0px; height: 1px; overflow: visible; position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-sEk2l .framer-1yxpr6w { gap: 0px; } .framer-sEk2l .framer-1yxpr6w > * { margin: 0px; margin-bottom: calc(24px / 2); margin-top: calc(24px / 2); } .framer-sEk2l .framer-1yxpr6w > :first-child { margin-top: 0px; } .framer-sEk2l .framer-1yxpr6w > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 507
 * @framerIntrinsicWidth 405
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"toaKH4Vnc":"background","LoGuNMAV5":"image"}
 */
const FramerLGAWBJqXX: React.ComponentType<Props> = withCSS(Component, css, "framer-sEk2l") as typeof Component;
export default FramerLGAWBJqXX;

FramerLGAWBJqXX.displayName = "Card";

FramerLGAWBJqXX.defaultProps = {height: 507, width: 405};

addPropertyControls(FramerLGAWBJqXX, {toaKH4Vnc: {defaultValue: "rgb(19, 88, 73)", title: "Background", type: ControlType.Color}, LoGuNMAV5: {title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerLGAWBJqXX, [])